import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useToastNotification } from '@shared/ui/overlay/Toast';
import { getExchangeSvgIcon } from '@shared/ui/icons/ExchangeIcon';
import { ReactComponent as DeleteIcon } from '@icons/wolfkit-light/trash-light.svg';
import SettingsRow from '@shared/ui/settings/SettingsRow';
import SettingsNameCell from '@shared/ui/settings/SettingsNameCell';
import SettingsValueCell from '@shared/ui/settings/SettingsValueCell';
import { useDeleteExchangeAccountMutation } from '@shared/api/exchange';
const ApiKeyRow = ({ exchange, }) => {
    const { t } = useTranslation();
    const { toast } = useToastNotification();
    const [deleteExchangeAccount, { isLoading, error, isError, },] = useDeleteExchangeAccountMutation();
    const handleDelete = (accountId) => {
        deleteExchangeAccount({ accountId });
    };
    if (isError) {
        toast({
            message: JSON.stringify(error),
            variant: 'error',
        });
    }
    return (_jsx(SettingsRow, { name: (_jsx(SettingsNameCell, { title: exchange.properties.connectionName, Icon: getExchangeSvgIcon(exchange.type), keepOriginalIconLook: true })), value: _jsx(SettingsValueCell, {}), manageBtnProps: {
            color: 'error',
            startIcon: DeleteIcon,
            children: t('actions.delete', { ns: 'common' }),
            isLoading,
            onClick: () => handleDelete(exchange.id),
        } }, exchange.id));
};
export default ApiKeyRow;
