import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { BodySmall, TitleSecondary } from '@components/styled/Typography';
import { ReactComponent as TraderIconComponent } from '@icons/wolfkit-light/crown-light.svg';
import { openCreateTraderForm as openTraderFormAction } from '@entities/profile';
import { GlobalSidebarElements, openSidebar } from '@store/slices/application';
import { useAppDispatch } from '@app/store/Hooks';
import { ContainerColumn } from '@src/components/styled';
const AvatarContainer = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 64,
    height: 64,
    minWidth: 64,
    borderRadius: '50%',
    backgroundColor: props.theme.customColors.badge.trader.surface,
}));
const CreateTraderContainer = styled.div(props => ({
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'start',
    padding: `${props.theme.spacing_sizes.xs * 3.25}px ${props.theme.spacing_sizes.l}px`,
    gap: props.theme.spacing_sizes.xs * 2,
    backgroundColor: props.theme.customColors.input.backgroundDisabled,
    borderRadius: props.theme.spacing_sizes.xs * 1.25,
    border: `2px solid ${props.theme.customColors.input.backgroundDisabled}`,
    cursor: 'pointer',
    flex: 1,
    '&:hover': {
        backgroundColor: props.theme.customColors.button.warning.hover,
        borderColor: '#FFD386',
    },
    '&:active': {
        borderColor: props.theme.palette.warning.main,
        boxShadow: '0px 0px 0px 3px rgba(255, 178, 36, 0.20)',
    },
}));
const TraderIcon = styled(TraderIconComponent)(props => ({
    width: 24,
    height: 24,
    path: {
        fill: props.theme.customColors.badge.trader.onSurface,
    },
}));
const TextContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const Title = styled(TitleSecondary)(props => ({
    lineHeight: `${props.theme.spacing_sizes.xs * 2.5}px`,
}));
const Description = styled(BodySmall)(props => ({
    fontSize: props.theme.spacing_sizes.xs * 1.75,
}));
const CreateTraderProfile = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const onClick = () => {
        dispatch(openTraderFormAction());
        dispatch(openSidebar(GlobalSidebarElements.CreateTraderProfile));
    };
    return (_jsxs(CreateTraderContainer, { onClick: onClick, children: [_jsx(AvatarContainer, { children: _jsx(TraderIcon, {}) }), _jsxs(TextContainer, { children: [_jsx(Title, { children: t('settings.tabs.personal_info.add_profile.trader.title') }), _jsx(Description, { children: t('settings.tabs.personal_info.add_profile.trader.text') })] })] }));
};
export default CreateTraderProfile;
