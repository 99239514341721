import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { ReactComponent as XMarkIcon } from '@icons/wolfkit-solid/xmark-circle-solid.svg';
import { ReactComponent as CheckmarkIcon } from '@icons/wolfkit-solid/checkmark-circle-solid.svg';
import IconText from '@shared/ui/icons/IconText';
import Icon from '@shared/ui/icons/Icon';
const KYCValue = ({ isUserDefined = undefined, isVerifiedTrader = undefined, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { text, icon, color } = isUserDefined && isVerifiedTrader ? {
        text: t('settings.tabs.personal_info.main_table.kyc.values.verified'),
        icon: _jsx(Icon, { IconComponent: CheckmarkIcon, color: theme.palette.success.main }),
        color: theme.palette.success.main,
    } : {
        text: t('settings.tabs.personal_info.main_table.kyc.values.unverified'),
        icon: _jsx(Icon, { IconComponent: XMarkIcon }),
        color: undefined,
    };
    return (_jsx(IconText, { text: text, IconComponent: icon, containerGap: theme.spacing_sizes.m, textColor: color }));
};
export default KYCValue;
