import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import SettingsRow from '@shared/ui/settings/SettingsRow';
import SettingsValueCell from '@shared/ui/settings/SettingsValueCell';
import { ReactComponent as KeyIcon } from '@icons/wolfkit-light/key-light.svg';
import Icon from '@shared/ui/icons/Icon';
import IconText from '@shared/ui/icons/IconText';
import { ConnectExchangeButton } from '@features/exchange/exchange-connect';
const ApiKeysEmpty = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (_jsx(SettingsRow, { name: (_jsx(IconText, { IconComponent: _jsx(Icon, { IconComponent: KeyIcon, size: 16 }), text: t('settings.tabs.personal_info.api_keys.empty_text'), containerGap: theme.spacing_sizes.s, textFontSize: 16, textLineHeight: '26px' })), value: _jsx(SettingsValueCell, {}), ManageBtn: (_jsx(ConnectExchangeButton, { color: 'primary', variant: 'filled', size: 'small', children: t('actions.connect', { ns: 'common' }) })) }, 'connect-exchange'));
};
export default ApiKeysEmpty;
